// Vuex
import { mapState } from 'vuex'

export default {
  name: 'CardCentered',
  props: {
    /**
     * https://vuetifyjs.com/en/components/alerts/#alerts
     */
    cardVOptions: {
      default() {
        return {
          width: '100%',
          'max-width': '600px',
          elevation: 1
        }
      },
      type: Object
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Get options (computed) to v-card
     */
    getCardVOptions() {
      return this.extraSmallDevice
        ? {
            ...this.cardVOptions,
            width: '100%',
            'max-width': '100%',
            height: '100%',
            elevation: 0
          }
        : this.cardVOptions
    }
  }
}
